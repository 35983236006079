<template>
  <div class="wrapper">
    <div
      class="page-header page-header-small header-filter"
      filter-color="orange"
    >
      <parallax
        class="page-header-image"
        style="background-image: url('img/bg5c.jpg')"
      >
      </parallax>
      <div class="container" style="padding-top: 0px; margin-top: 20px;">
        <div class="photo-container">
          <img :src="imagen" alt="" />
        </div>
        <h3 class="title">{{ nombre }}</h3>
        <p class="category">{{ titulo }}</p>
        <div class="content" style="margin-bottom: 20px;">
          <ul class="listado">
            <li>
              <a :href="'mailto:' + email" class="title">Email: {{ email }}</a>
            </li>
            <li v-if="telefono != null">
              <a :href="'tel:' + telefono" class="title"
                >Teléfono: {{ telefono }}</a
              >
            </li>
            <li v-if="celular != null">
              <a :href="'tel:' + celular" class="title"
                >Celular: {{ celular }}</a
              >
            </li>
            <li v-if="direccion != null">
              <a
                :href="'https://www.google.com/maps/dir/' + direccion"
                class="title"
                >Dirección: {{ direccion }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="button-container">
          <a
            v-if="celular != null"
            :href="'tel:' + celular"
            class="btn btn-primary btn-round btn-lg"
            >Llamame</a
          >
          <a
            v-if="celular != null"
            :href="'http://wa.me/+52' + celular"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Twitter"
          >
            <i class="fab fa-whatsapp"></i>
          </a>
          <a
            :href="'mailto:' + email"
            class="btn btn-default btn-round btn-lg btn-icon"
            rel="tooltip"
            title="Follow me on Instagram"
          >
            <i class="now-ui-icons ui-1_email-85"></i>
          </a>
        </div>
        <div class="imagen col-md-8 ml-auto mr-auto" :url="'https://www.polizajuridica.com.mx/#/tarjeta/'+email">
          <img v-lazy="logo" alt="" />
        </div>
        <div class="text-center col-md-8 ml-auto mr-auto">
          <a
            href="https://www.instagram.com/polizajuridica/?hl=es"
            class="btn btn-icon btn-lg btn-round btn-reddit instagram-sharrre sharrre"
            rel="tooltip"
            title="Instagram"
            data-original-title="Instagram!"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/PolizaJuridicaMx/"
            class="btn btn-lg btn-round btn-icon btn-facebook facebook-sharrre sharrre"
            rel="tooltip"
            title="Facebook"
            data-original-title="Share!"
          >
            <i class="fab fa-facebook-square"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/p%C3%B3liza-jur%C3%ADdica"
            class="btn btn-lg btn-round btn-icon btn-linkedin linkedin-sharrre sharrre"
            rel="tooltip"
            title="Linkedin"
            data-original-title="Share!"
          >
            <i class="fab fa-linkedin"></i>
          </a>
          <a
            :href="'https://www.polizajuridica.com.mx/#/'+representacion"
            class="btn btn-lg btn-round btn-icon btn-primary"
            rel="tooltip"
            title="Página Web"
            data-original-title="Share!"
          >
            <i class="fab fa-dribbble"></i>
          </a>
        </div>
        <div class="container" style="text-align: center; justify-content: center; align-items: center; height">
          <h4 style="text-align: center;">Codigo Qr</h4>
          <img :src="qrCodeSrc" alt="Código QR">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Badge,
  Button,
  InfoSection,
  FormGroupInput,
  Card,
  Tabs,
  TabPane,
  Checkbox,
} from "@/components";
import ServUsuarios from "../Servicios/Usuarios.js";
import logo from "../../public/imagenes/Logo_poliza.webp";
export default {
  name: "profile-page",
  bodyClass: "profile-page",
  components: {
    InfoSection,
    Card,
    Tabs,
    TabPane,
    Badge,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      usuarios: [],
      nombre: null,
      titulo: null,
      celular: null,
      telefono: null,
      email: null,
      direccion: null,
      imagen: null,
      logo: logo,
      representacion: null,
      url: "",
      qrCodeSrc: ''
    };
  },
  methods: {
    retrive(nombre) {
      ServUsuarios.Usuario(nombre)
        .then((r) => {
          console.log(r.data);
          let u = r.data;
              this.nombre = u.usuarioNomCompleto;
              this.titulo = u.titulo;
              this.celular = u.usuarioCelular;
              this.telefono = u.usuarioTelefono;
              this.email = u.usurioEmail;
              this.direccion = u.representacion.direccion;
              this.imagen = u.imagen;
              this.representacion = u.representacion.representacionNombre;
              this.generateQRCode(this.email);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    generateQRCode(email) {
      this.url = `https://www.polizajuridica.com.mx/#/tarjeta/${email}`;
      this.qrCodeSrc = 'https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=' + encodeURIComponent(this.url);
    }
  },
  created(){
    let nombreURL = this.$route.params.nombre.toLowerCase();
    if (nombreURL != null || nombreURL != undefined) {
        this.retrive(nombreURL);
      }
      
  },
  watch: {
    $route(to) {
      //Vamos a interpretar
      let nombreURL = to.params.nombre.toLowerCase();
      if (nombreURL != null || nombreURL != undefined) {
        this.retrive(nombreURL);
      }
    },
  },
};
</script>
<style>
.redesSolciales {
  margin-top: -50%;
}
.listado {
  font-size: 14px;
  margin-left: -15%;
  list-style: none;
}
.imagen{
  height: 100px;
  width: 350px;
}
</style>
